// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder. 
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.


$primary: #efb310;
$screen-md-min:320px;
$black:#000;
$darker:#292929;
$light:#e9ecef;

$nav-pills-link-active-color:#000;
$nav-pills-link-active-bg:#e9ecef;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    10: 10%,
	12: 12%,
    15: 15%,
	18: 18%,
    20: 20%,
  ),
  $sizes
);