@import url("https://use.typekit.net/atb6dqn.css");
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:local('Open Sans Light'), local('OpenSans-Light'), url(//fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTa-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:local('Open Sans Light'), local('OpenSans-Light'), url(//fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTZX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:local('Open Sans Light'), local('OpenSans-Light'), url(//fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRWV49_lSm1NYrwo-zkhivY.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:local('Open Sans Light'), local('OpenSans-Light'), url(//fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTaaRobkAwv3vxw3jMhVENGA.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:local('Open Sans Light'), local('OpenSans-Light'), url(//fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTf8zf_FOSsgRmwsS7Aa9k2w.woff2) format('woff2');unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:local('Open Sans Light'), local('OpenSans-Light'), url(//fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTT0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:local('Open Sans Light'), local('OpenSans-Light'), url(//fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:local('Open Sans'), local('OpenSans'), url(//fonts.gstatic.com/s/opensans/v13/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:local('Open Sans'), local('OpenSans'), url(//fonts.gstatic.com/s/opensans/v13/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:local('Open Sans'), local('OpenSans'), url(//fonts.gstatic.com/s/opensans/v13/LWCjsQkB6EMdfHrEVqA1KRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:local('Open Sans'), local('OpenSans'), url(//fonts.gstatic.com/s/opensans/v13/xozscpT2726on7jbcb_pAhJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:local('Open Sans'), local('OpenSans'), url(//fonts.gstatic.com/s/opensans/v13/59ZRklaO5bWGqF5A9baEERJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:local('Open Sans'), local('OpenSans'), url(//fonts.gstatic.com/s/opensans/v13/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:local('Open Sans'), local('OpenSans'), url(//fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:local('Open Sans Semibold'), local('OpenSans-Semibold'), url(//fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSq-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:local('Open Sans Semibold'), local('OpenSans-Semibold'), url(//fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:local('Open Sans Semibold'), local('OpenSans-Semibold'), url(//fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNShWV49_lSm1NYrwo-zkhivY.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:local('Open Sans Semibold'), local('OpenSans-Semibold'), url(//fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSqaRobkAwv3vxw3jMhVENGA.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:local('Open Sans Semibold'), local('OpenSans-Semibold'), url(//fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSv8zf_FOSsgRmwsS7Aa9k2w.woff2) format('woff2');unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:local('Open Sans Semibold'), local('OpenSans-Semibold'), url(//fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:local('Open Sans Semibold'), local('OpenSans-Semibold'), url(//fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:local('Open Sans Bold'), local('OpenSans-Bold'), url(//fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzK-j2U0lmluP9RWlSytm3ho.woff2) format('woff2');unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:local('Open Sans Bold'), local('OpenSans-Bold'), url(//fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzJX5f-9o1vgP2EXwfjgl7AY.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:local('Open Sans Bold'), local('OpenSans-Bold'), url(//fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzBWV49_lSm1NYrwo-zkhivY.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:local('Open Sans Bold'), local('OpenSans-Bold'), url(//fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKaRobkAwv3vxw3jMhVENGA.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:local('Open Sans Bold'), local('OpenSans-Bold'), url(//fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzP8zf_FOSsgRmwsS7Aa9k2w.woff2) format('woff2');unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:local('Open Sans Bold'), local('OpenSans-Bold'), url(//fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzD0LW-43aMEzIO6XUTLjad8.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:local('Open Sans Bold'), local('OpenSans-Bold'), url(//fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;}
@font-face{font-family:'Oswald';font-style:normal;font-weight:300;src:local('Oswald Light'), local('Oswald-Light'), url(//fonts.gstatic.com/s/oswald/v11/l1cOQ90roY9yC7voEhngDBJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Oswald';font-style:normal;font-weight:300;src:local('Oswald Light'), local('Oswald-Light'), url(//fonts.gstatic.com/s/oswald/v11/HqHm7BVC_nzzTui2lzQTDVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;}
@font-face{font-family:'Oswald';font-style:normal;font-weight:400;src:local('Oswald Regular'), local('Oswald-Regular'), url(//fonts.gstatic.com/s/oswald/v11/yg0glPPxXUISnKUejCX4qfesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Oswald';font-style:normal;font-weight:400;src:local('Oswald Regular'), local('Oswald-Regular'), url(//fonts.gstatic.com/s/oswald/v11/pEobIV_lL25TKBpqVI_a2w.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;}
@font-face{font-family:'Oswald';font-style:normal;font-weight:700;src:local('Oswald Bold'), local('Oswald-Bold'), url(//fonts.gstatic.com/s/oswald/v11/dI-qzxlKVQA6TUC5RKSb3xJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Oswald';font-style:normal;font-weight:700;src:local('Oswald Bold'), local('Oswald-Bold'), url(//fonts.gstatic.com/s/oswald/v11/bH7276GfdCjMjApa_dkG6VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;}
@import url(//fonts.googleapis.com/css?family=Roboto+Condensed:300&subset=latin,cyrillic,latin-ext,cyrillic-ext,greek,greek-ext,vietnamese);
@import url(//fonts.googleapis.com/earlyaccess/amiri.css);


// Variables //

$nav-pills-link-active-color:#000;
$nav-pills-link-active-bg:#e1e1e1;

$bg-light:#e1e1e1;

/*arrows*/
// Button settings
$btn-size:             50px;
$btn-arrowsize:           $btn-size / 4;

// Button colors
$btn-color:               #E8ECEF;
$btn-bg:                  rgba(0, 0, 0, 0.1);;
$btn-border:              #E8ECEF;

// Button hover colors
$btn-hover-color:         #111;
$btn-bg-hover:            $primary;
$btn-hover-border-color:  $primary;

.bg-black {
  background-color:$black;
}
.bg-darker{background-color:$darker;}

.rtl{
	direction: rtl!important;
	unicode-bidi: embed!important;
	text-align: right;
}
.amiri {
    font-family: 'Amiri', serif;
    font-size: 1.5em;
    line-height: 1.2em;
    font-weight: normal;
}
#main{
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    //font-family: inherit;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
	}
	.highlight{
		padding-left:10px;
		border-left: 4px $primary solid;
		border-left-width: .25rem;
		border-radius: .25rem;
		line-height: 1;
		}
}
.green-fleet {
	li{
  		border-bottom: 1px solid #b6d65e;
	}
	li:hover{
  		background-color: #f6f9eb;
	}
	a{
		color:$dark;
		text-decoration: none;
	}
	a:hover{
		color: $dark;
		text-decoration: none;
	}
	
}
a {
  color: #003EC7;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: $primary;
      text-decoration: underline; }

//* START NAVIGATION*//
.main-nav{height:36px}
#main-nav ul{list-style:none;list-style-type: none;margin:0;padding:0;font-weight:400; }
#menu-main-nav{background-color:#000;position:relative;margin:0 0 0 0;}
#menu-main-nav ul {min-width:900px;}
#menu-main-nav > li{float:left;list-style-type:none;z-index:50;font-size:.8em;}
#menu-main-nav > li > a {
	display: block;
	text-decoration: none;
	color: #efb310;
	height: inherit;
	padding: 1rem;
	padding: 9px 0 9px 0;
    width: 150px;
	text-align: center; 
	text-transform: uppercase;
}
#menu-main-nav li.active a,#menu-main-nav li.active-sub a{color:#fff}
#menu-main-nav li:hover div{left:0}
#menu-main-nav li:hover #supply-store-box{left:145px}
#menu-main-nav > li:hover > a{background:#222 url(images/hover_triangle.png) no-repeat 50% bottom;color:#FFF}


.drop{position:absolute;background-color:#FFF;left:-9999px;z-index:10001!important;-webkit-box-shadow:0 5px 5px rgba(0,0,0,.35);-moz-box-shadow:0 5px 5px rgba(0,0,0,.35);box-shadow:0 5px 5px rgba(0,0,0,.35);border:1px solid #DDD;border-top:none;width:80%;padding:10px 0 10px;}
.drop-content{overflow:hidden;display:block;padding:0 20px;font-size:.925em;color:#444;}
.drop-content .gallery-item{float:left;margin:5px}
.drop-content .btn{margin-top:10px}
#header{height:94px;position: relative;}
#header-left{float:left;}
#logo{padding:20px 0 0 0;}

.results-link a{color:#444!important;}

#utility-nav{
	float: right;
	font-size:.825rem;
	padding-top:25px;
	
	.nav-link{color:#555; text-decoration:none;}
	.nav-link:hover{text-decoration:underline;}
}
#header-search{float:right;margin-top:14px;}

#main-nav{height:36px;}
#main-nav ul{list-style:none;list-style-position:inside;text-transform:uppercase;margin:0;padding:0; font-weight:normal; padding-left: 0; width: 800px;}
#menu-main-nav > li:hover > a{background:#222 url(hover_triangle.png) no-repeat 50% bottom;color:#FFF}
ul.dropdown {position:relative;}
ul.dropdown li{float:left;zoom:1;font-size:0.8em;color:#efb310;}
ul.dropdown a:hover{color:#fff;text-decoration:none;}
ul.dropdown a:active{color:#efb310;text-decoration:none;}
ul.dropdown li a{width:140px;height:26px;display:block;padding-top:8px;text-align:center;color:#efb310;text-decoration:none;}
ul.dropdown li.active a, ul.dropdown li.active-sub a{color:#fff}
ul.dropdown li:last-child a{border-right:none;text-decoration:none;}
ul.dropdown li.hover, ul.dropdown li:hover{color:#fff;position:relative;}
ul.dropdown li a:hover{color:#fff;text-decoration:none;}
ul.dropdown ul{width:200px;visibility:hidden;position:absolute;top:100%;left:0;z-index:100000;}
ul.dropdown ul li{background:#ffcb2f;color:#000;border-bottom:1px solid #ccc;float:none;}
ul.dropdown ul li a{color:#000;border-right:none;width:100%;display:inline-block;text-align:left;padding-left:20px;text-decoration:none;}
ul.dropdown ul li a:hover{color:#fff;}
ul.dropdown li.current{color:#fff;cursor:default;}

.grey-navbar {
    min-height: 36px;
    height: 36px;
    background-color: #e1e1e1;
}
.panel{background-color: #fff!important}
.slideout-menu {
  position:fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: none;
background-color:#333;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}
@media (min-width: 992px) {
	.panel {transform: translateX(0px)!important;}
	.slide-menu{padding-top:0!important;
}
}
.slide-menu .menu-section-list,.slide-menu .menu-section-list-drop{
	list-style: none;
	margin: 0;
	padding-left:0;
}
.menu-section-list li {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	border-bottom: 1px solid #aaa;
}
.menu-section-list li a{
	display: block;
    padding: 15px 15px 13px 18px;
	color: #efb310;
    text-transform: uppercase;
}
.menu-section-list li .sub-menu{
	margin-bottom:10px;
}
.menu-section-list li .sub-menu li{
	border-bottom: none;
}
.menu-section-list li .sub-menu li a{
	font-size:14px;
	display: block;
	color:#aaa;
	padding: 5px 0 5px 30px;
	text-transform:none;
}
.menu-section-list-drop{
	border-bottom: 1px solid #aaa;
	padding-bottom:10px!important;
}
.menu-section-list-drop li.first a{
	padding: 15px 15px 13px 18px!important;
	color: #efb310;
	text-transform: uppercase;
}
.menu-section-list-drop li a{
	display: block;
	color:#aaa;
	padding: 5px 0 5px 30px;
	
}
.mobile-bar{min-height:30px;background-color: #000;}
.mobile-nav-close{
    margin-top:-15px;
	font-size: 64px;
	font-weight: 400;
	color: #aaa!important;
	padding: 10px 15px 10px 0;
	line-height: 40px!important;
}
#menu-mobile-main-nav .sub-menu li{
    width:100%;text-align:left;white-space: normal!important
}
#menu-mobile-main-nav ul.sub-menu li.active a{
    color:#efb310!important;
}
#menu-mobile-main-nav .top-level .fa {
	float: right;line-height:20px
}
.mobileNavWrapper-e6a8d885bf70{border-bottom:none!important;margin-bottom:0!important;padding-bottom:3px!important}
.mobile-search{background-color: #000;border-bottom:2px solid #efb310;margin-top:-5px;padding:0 10px 10px 10px;}
.mobile-search label{display: none;}
.mobile-form-input{height:33px!important;}
.btn-mobile{padding: .2rem .5rem!important}

/* Mobile Nav backup classes */
.mobileNavWrapper {
font-family: "Open Sans", Arial, HelveticaNeue, "Helvetica Neue", Helvetica, sans-serif;
padding-top: 10px;
display: inline-block;
font-size: 12px;
width: 100%;
background-color: #000;
border-bottom:none!important;
margin-bottom:0!important;
padding-bottom:3px!important
}

.mobileNavWrapper hr {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 0px;
}

.mobileNavWrapper .user-link-container {
  padding: 0px 10px 5px 10px;
}

.mobileNavWrapper .user-link-container .portal-link {
  margin: 5px 0px;
}

.mobileNavWrapper .user-link-container .portal-link a {
  text-decoration: none;
  font-size: 14px;
  color: #EFB310;
}

.mobileNavWrapper .user-link-container .portal-link a:hover,
.mobileNavWrapper .user-link-container .portal-link a:active,
.mobileNavWrapper .user-link-container .portal-link a:visited {
  color: #EFB310;
}

.mobileNavWrapper .user-link-container .portal-link a:hover {
  text-decoration: underline;
}

.mobileNavWrapper .portal-mobile-bar-wrapper {
  padding: 0px 10px;
}

.mobileNavWrapper .mobile-buttons {
  width: 25%;
  float: left;
  font-size: 24px;
  color: #fff;
  margin-top: -2px;
}

.mobileNavWrapper .mobile-buttons a {
  color: #fff;
  text-decoration: none;
  margin-right: 9px;
}

.mobileNavWrapper .mobile-buttons a:visited,
.mobileNavWrapper .mobile-buttons a:hover,
.mobileNavWrapper .mobile-buttons a:active {
  color: #fff;
}

.mobileNavWrapper .mobile-buttons a.active-icon {
  color: #EFB310;
}

.mobileNavWrapper .mobile-buttons a.active-icon:visited,
.mobileNavWrapper .mobile-buttons a.active-icon:hover,
.mobileNavWrapper .mobile-buttons a.active-icon:active {
  color: #EFB310;
}

.mobileNavWrapper .right-align {
  text-align: right;
}

.mobileNavWrapper .left-align {
  text-align: left;
}

.mobileNavWrapper #cart {
  position: relative;
}

.mobileNavWrapper .mobile-logo {
  width: 50%;
  float: left;
  text-align: center;
}

.mobileNavWrapper .mobile-logo img {
  width: 104px;
}


ol ol, ol ul, ul ol, ul ul {

    margin-bottom: 0;
    padding-left: 0;

}
.menu-col-4{ margin-bottom: 12%; width:33%;float:left;margin-bottom:0;padding:2%;}
.menu-col-3{width:29.25%;float:left;margin-bottom:0;padding:2%;}
.menu-last{background-image:none}
.menu-col-4 h3, .menu-col-3 h3{ padding-left:0!important;margin-left:0!important;padding-bottom:10px;font-size:16px;}
.menu-col-4 ul li, .menu-col-3 ul li{list-style-type:none; list-style-position:inside; font-size:14px; margin: 0;
    padding-left: 0;}
.menu-col-4 ul li a, .menu-col-3 ul li a{color:#444;list-style-type:none; list-style-position:inside; }


.desktop-site-nav {
	position:relative;
  	li {
    	a {
      		font-size: 16px !important;
      		font-weight: normal !important;
      		text-transform: uppercase;
      		color: $primary;
    	}
    .drop-content > .menu-col-4 {
      @extend .col-md-4;
    }

  }
}
@media(min-width: $screen-md-min) {
  .desktop-site-nav {
    li {
      a {
        font-size: 12.8px !important;
        font-weight: normal !important;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        height: inherit;
        line-height: 19px;
        padding: 8px 0 8px 0 !important;
        width: 140px;
        text-align: center;
      }

      .site-nav-drop {
        position: absolute;
        background-color: #FFF;
        z-index: 10001 !important;
        -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .35);
        -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, .35);
        box-shadow: 0 5px 5px rgba(0, 0, 0, .35);
        border: 1px solid #DDD;
        border-top: none;
        width: 833px;
        padding: 10px 0 20px;
      }

      #mega-menu-45108{
        left:0;
      }
      #mega-menu-45109{
        right:0;
      }
		
      .drop-content {
        overflow: hidden;
        display: block;
        padding: 0 20px;
        font-size: 11.84px;
        color: #444;

        p {
		text-align: left;
          padding: 0;
          margin: 0;
        }

        .menu-last {
          background-image: none !important;
        }

        .menu-col-4 {
          padding: 15px;
          width: 30%;

          .altec-col-title {
            font: 18px/1 Oswald, arial, helvetica, sans-serif !important;
            margin-top: 0;
            margin-bottom: 4px;
            margin-left: 0 !important;
            padding-bottom: 10px;
            padding-left: 0 !important;
            position: relative;
            color: #666 !important;
            text-align: left;
            font-size: 16px;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-weight: normal;

            li {
              font-size: 14px;
              padding: 2px 0;

              a {
                color: #444;
                text-align: left;
                height: auto;
                width: auto;
                line-height: 18px;
                font-size: 14px !important;
                padding: 0 !important;
                text-transform: capitalize !important;
              }

              &:hover {
                background: none;
                background-color: #FFF !important;
                color: #444 !important;
                text-decoration: underline;
              }
            }
          }

          .btn-primary {
            margin: 10px 0 5px;
            padding: 5px 9px !important;
            font-size: 12px !important;
            line-height: 19px;
            color: #FFF !important;
            height: auto;
            width: auto;

            &:hover {
              background: none;
              background-color: #d7a10f!important;
              color: #f2f2f2!important;
            }
          }
        }
      }

      &:hover {
        background: url(hover_triangle.png) no-repeat 50% bottom;
        background-color: #222 !important;
        color: white !important;
      }
    }
  }
}

.navbar-black .navbar-nav .dropdown-menu .nav-link {
  display: block !important;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #fff !important;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; 
  margin-bottom: 0px;
  padding: 1.5rem;
	
  }
  .navbar-black .navbar-nav .dropdown-menu .nav-link:hover, .navbar-black .navbar-nav .dropdown-menu .nav-link:focus {
    color: #fff !important;
    text-decoration: none;
    background-color: #fff;
   margin-bottom: 0px;}
  .navbar-black .navbar-nav .dropdown-menu .nav-link.active, .navbar-black .navbar-nav .dropdown-menu .nav-link:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #efb310;
margin-bottom: 0px;}
  .navbar-black .navbar-nav .dropdown-menu .nav-link.disabled, .navbar-black .navbar-nav .dropdown-menu .nav-link:disabled {
    color: #6c757d !important;
    background-color: transparent;
}

.navbar-black .navbar-brand a {
  color: #fff;
}
.navbar-black .navbar-brand a:hover, .navbar-dark .navbar-brand a:focus {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
color: rgb(239, 174, 94);}
.navbar-nav a{color:$primary;
text-transform: uppercase;
font-size:.8em;
padding:1.5rem 7px}

.nav-fill .nav-item{
	-webkit-box-flex:1;
	-ms-flex:1 1 auto;
	flex:1 1 auto;
	text-align:center
}
@media screen and (max-width: 850px) {
.hero-gradient {
padding-bottom:50px!important;
}
.navbar-expand-md .navbar-nav {
    display:hidden;
}
}
#left-nav{font-size:.875em;font-weight:700;list-style: none; max-width:250px;margin-bottom:40px;}

@media screen and (max-width: 800px) {
	#left-nav, .right-sidebar {
	text-align: center;
		align-content: center;
		min-width:100%;
		margin:0;
		display:none;
	}
}

#left-nav ul{margin:0; list-style: none;padding:0}
#left-nav ul li.first a{color:$primary;font-size:medium;}
#left-nav ul li{text-transform:uppercase;border-bottom:1px solid #d2d2d2;padding:6px 0px;}
#left-nav ul li a{color:#555}
#left-nav ul li.hover a{color:$primary}
#left-nav ul li.active a{color:$primary}
#left-nav ul li ul li{font-size:.857em;border:none;display:none}
#left-nav ul li.active ul li{display:block;padding-left:.75rem}
#left-nav ul li.active ul li a{color:#555}
#left-nav ul li.active-parent a,#left-nav ul li.active-sub a{color:$primary}
#left-nav ul li.active-parent ul li,#left-nav ul li.active-sub ul li{display:block}
#left-nav ul li.active-parent ul li a,#left-nav ul li.active-sub ul li a{color:#555}
#left-nav ul li.active-parent ul li.active a,#left-nav ul li.active-sub ul li.active-sub a{color:$primary}

.black_bottom {color:white;background-color: black;list-style: none;padding-top:37px;padding-bottom:15px}
.black_bottom_right {color:white;background-color: black;list-style: none;border-left: solid thin  #FFF;}
@media screen and (max-width: 800px) {
.black_bottom_right {
	text-align: center;
	border-left: none;
	}
}
@media screen and (max-width: 800px) {
.black_bottom{
	text-align: center;
	border-left: none;
	}
}
.black_bottom a {color:white;}
.black_bottom a:hover {color:$primary;}
.black_bottom ul li  {list-style: none; display: inline; text-indent: 0; margin-right: 10px; border-right: 1px solid #555; padding-right:10px;}
.black_bottom ul li:last-of-type {border-right: none;}
.black_bottom ul ul li {border-right: none;}
.black {color:white;background-color: black;list-style: none;padding: 25px }
//* END NAVIGATION*//


.wrapper {
  padding: 0; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; 
  
}
#1stcontentareahome{padding-left:12%;padding-right:12%;background-color:#292929;}
#content_row {padding-left: 10%; padding-right: 10%; background-color: #292929; color: #fff; padding-top: 20px; margin:0px;}
#content_row hr {
height: 1px;
color: $primary;
background-color: $primary;
width:75%;	
margin-left: auto;
	margin-right: auto;
	border:0;
	
}
div#content_row a{ color:$primary;}
div#content_row_1 a{ color:$primary;}
#content_row_1 { padding:0 5% 0px 5%; margin-top:0;}
.footerfull {background-color:$light!important;}
.fourfooter ul{margin:0; text-indent: 0;padding-left : 0; list-style-type: none;}
.fourfooter a{color:#212529;}
.fourfooter a:hover{color:$primary;}
.footerfull col-md{margin-top: 10px;}
.footerfull content{margin:0px;}
.fourfooter {padding:20px 20px 15px ;}
.fourfooter h3{text-transform: uppercase;border-bottom:1px solid #ccc;padding-bottom:10px;font-size: 1em; font-weight:bold;}


//FOOTER//
.black_bottom_right .simple-social-icons {
  overflow: hidden;
  font-size: 16px;
}

.black_bottom_right .simple-social-icons ul {
  padding: 0;
}

.black_bottom_right .simple-social-icons ul li {
  display: inline-block;
  padding: 8px;
}

.black_bottom_right .simple-social-icons ul li:first-child {
  padding-left: 0;
}

.black_bottom_right .simple-social-icons ul li a {
  color: white;
}

 .black_bottom_right .simple-social-icons ul li a:hover {
  color: #EFB310;
}

@media (max-width: 991px) {
	.weather-alert{text-align: center}
	.footerfull .black {
		text-align: center;
	}

	.footerfull .black .black_bottom_right {
		border-left: none;
	}
}

.content-area{padding-top:25px;margin-bottom:$spacer !important}
.no-mp{
	margin: 0!important;
	padding: 0!important;
}
.home-containers{margin: 0!important;padding: 0!important}

@media screen and (max-width: 800px) {
	.fourfooter {
	text-align: left;
		align-content: center;
		width:100%;
	}
}
@media screen and (max-width: 800px) {
	#content_row_1 .col-sm-6{
	padding:5%;
	}
}
#bigleft {border-right: 1px dotted #555;border-left: 1px dotted #555;  padding-right:10px;}
#colright {border-right: 1px dotted #555; border-left: 1px dotted #555; padding-left:15px; }
#menu-footer-base-links {text-indent: 0;padding-left : 0;margin:0;}
#wrapper-static-hero {padding:0;}
#grey_box {padding:25px;color: #000; margin:15px;border-radius: 15px;}
#service-sidebar{margin:25px 0 0 25px;}
#prima
.carousel-inner .item,
.carousel-inner .item.active,
.carousel-inner .item img {
    margin: auto;
    
}
.carousel-control-next, .carousel-control-prev{width:5%!important}
.jumborow {
 width: 100%; padding: 0px; margin: 0px; 
}

/*video starts here*/
.banner-content{min-height:250px!important;}

#bg-video {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.hero-content {
background-color: rgba(0,0,0, 0.0);
color: #FFFFFF; 
margin: 5% auto 20px auto;
text-transform: capitalize;
min-width: 320px;
z-index: -999999;
}
.hero-content h2{
	font-size: 2.5rem;
	color:$primary;
}
.hero-content h3{
	font-size:2rem;
}
@media screen and (max-width: 800px) {
.hero-content {
background-color: rgba(0,0,0,0);
color: #fff;
margin: 0;
text-transform: capitalize;
text-align: center;
z-index: -999999;
}
}

.hero-content-alt{
	background-color: rgba(0,0,0, 0.4);
	color: #FFFFFF; 
	padding-left:150px;
	padding-top:150px;
	text-transform: capitalize;
	min-width: 320px;
	height: 100%;
	text-align: left;
	z-index: -999999;
}
.parallax{ 
	background: url('http://newsite.altec.com/site/uploads/banner2-1.jpg') no-repeat; 
	background-size: cover; 
	margin: 0;
	height: 350px; 
    background-attachment: fixed;
    background-repeat: no-repeat;
	background-position: right;
}
@media screen and (max-width: 600px) {
.banner-content{min-height:300px!important;}
.parallax {
visibility: hidden;
height: 0px;
}
}
.hero-gradient {
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(41,41,41,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(41,41,41,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(41,41,41,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#292929',GradientType=0 ); /* IE6-9 */
  color: #FFFFFF; 
  padding: 5% 0;
  text-transform: capitalize;
}
* { box-sizing: border-box; }
.hero-background {
  background: #000;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -9000;
}
.hero-foreground,
.hero-background iframe {
  position: absolute;
  top: 0;
  left: -15%;
  width: 130%;
  height: 125%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .hero-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .hero-foreground { width: 300%; left: -100%; }
}
.hero-image {
	background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
	height: 100%
}

.hero-background-alt {
  background: #000;
  top: 0; right: 0; bottom: 0; left: 0;
}
.hero-foreground-alt,
.hero-background-alt iframe {
  top: 0;
  left: -5%;
  width: 110%;
  height: 110%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .hero-foreground-alt { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .hero-foreground-alt { width: 300%; left: -100%; }
}

/*buttons start here!*/
.product-btn{width:150px;font-size:.82rem}
.hero-banner{position: relative;height: 100%;}
.hero-buttons{
    position: absolute;
    top:70%;
    width:40%;
    left:3%;
    }


.btn-primary{
	color: #fff!important
}
.btn-light{
	background:$gray-200;
}
a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
background-color: #333 !important; }

.btn-group-justified {
  display: flex;
  width: 100%;
  .btn,
  .btn-group {
    flex: 1;
    .btn {
      width: 100%;
    }
    .dropdown-menu {
      left: auto;
    }
  }
}
.btn-grey {
  background: #ffffff;
  background-image: -webkit-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: -moz-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: -ms-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: -o-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: linear-gradient(to bottom, #ffffff, #e8e8e8);
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  font-family: Arial;
  color: #0a0a0a;
  font-size: 14px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;

}

.btn-yellow {
  background: $primary;
  background-image: -webkit-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: -moz-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: -ms-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: -o-linear-gradient(top, #ffffff, #e8e8e8);
  background-image: linear-gradient(to bottom, #ffffff, #e8e8e8);
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 3px #666;
  -moz-box-shadow: 0px 1px 3px #666;
  box-shadow: 0px 1px 3px #666;
  font-family: Arial;
  color: #0a0a0a;
  font-size: 14px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;

}
.btn-grey:hover {
  background: $primary;
  text-decoration: none;
  color: #0a0a0a;
}
.btn-outlined {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  font-family: Arial;
  border-color: $primary;
  border-width: thin;
	border-style: solid;
	color: #fff;
  font-size: 14px;
  padding: 8px 20px;
  text-decoration: none;

}
.btn-outlined:hover {
  text-decoration: none;
  color: $primary;
}

.products_home{
	padding:10px;
}
.arrowbtn {
  position: absolute;
  width: $btn-size;
  height: $btn-size;
  background: $btn-bg;
  border: 2px solid $btn-border;
  border-radius: $btn-size;
  color: $btn-color;
  cursor: pointer;
  left: 50%;
  line-height: $btn-size;
  margin-left: -($btn-size / 2);
  transition: all 0.25s ease-in-out;
  &:hover {
    background: $btn-bg-hover;
    border-color: $btn-hover-border-color;
    color: $btn-hover-color;
  }
  &:after {
    position: absolute;
    display: inline-block;
    content: "";
    width: $btn-arrowsize;
    height: $btn-arrowsize;
    top: 50%;
    left: 50%;
  }
}

.arrowbtn-up {
  top: 20px;
  &:after {
    margin-left: -($btn-arrowsize * .5);
    margin-top: -($btn-arrowsize * .25);
    border-top: 2px solid;
    border-left: 2px solid;
    transform: rotateZ(45deg);
  }
}

.arrowbtn-down {
  bottom: 20px;
  &:after {
    margin-left: -($btn-arrowsize * .5);
    margin-top: -($btn-arrowsize * .5);
    border-bottom: 4px solid;
    border-right: 4px solid;
    transform: rotateZ(45deg);
  }
}
ul#product_results{margin:0; overflow:auto;}
#product_results li{float:left;list-style:none;margin-bottom:15px;margin-left:15px;margin-top:20px;margin-right:15px;width:20%;}
#product_results h3{border-bottom:1px solid #e1e1e1;font:18px/1.3 Oswald, arial, helvetica, sans-serif;margin-bottom:4px;padding-bottom:2px;position:relative;margin-top:0;color:#666!important}
#product_results h3 a{color:#666!important}
#product_results h3 img{padding-right:3px}
#product_results p{font-size:11px;padding:0;text-transform:uppercase;color:#666!important;min-height:50px}
#product_results a{text-decoration:none;text-transform:none}
.forestry-h2 {
    text-transform: uppercase;
    color: #444;
    margin: 17px 0 16px;
    padding: 0 0 6px 10px;
    font-weight: 400 !important;
    font-size: 18px !important;
    background: url('http://newsite.altec.com/site/uploads/h2-back.jpg') bottom left no-repeat;
}
hr, .divider {
    border: solid #ededed;
    clear: both;
    display: block;
    height: 20px;
    border-width: 0 0 1px;
    margin: 0 0 20px;
}
hr.dotted, .divider.dotted, .divider-short.dotted {
    border-style: dotted;
}
.divider.none {
    border-style:none;
}

#top_greybox { padding:0 15% 0 15%;
 background-color:#222;
}

#product_results h3 {
   font: 16px/1.3 Oswald,arial,helvetica,sans-serif;
    margin-bottom: 4px;
    padding: 0 40px 0 0px;
        padding-bottom: 0px;
    position: relative;
    margin-top: 0;
    color: #666 !important;
}

#grey_rightrail{background-color:#E8ECEF;}

#products a {color:$primary}
#products{width:100%; margin:0 auto;}

.product-thumbnails h3 {
    font: 14px/1.3 Oswald, arial, helvetica, sans-serif;
    color: #666 !important;
}
.product-thumbnails h3 a{
    color: #666 !important;
}
.product-thumbnails h3 a:hover{
    color: $primary !important;
}

.product-cards{
	h5 a{
		font: 20px/1.3 Oswald, arial, helvetica, sans-serif;
    	color: #fff;
		font-weight:300;
	}
	h5 .fa{
		font-size: 20px;;
    	color: #efb310;
		font-weight: bold;
	}
	.card-body{
		padding:0 0 10px 15px!important
	}
}

.nueco-body{
	.card{
		min-height: 210px;
		border-radius:0
	}
	.top-left{
		border-radius:.25rem 0 0 0;
	}
	.top-right{
		border-radius:0 .25rem 0 0;
	}
	.bottom-left{
		border-radius:0 0 0 .25rem;
	}
	.bottom-right{
		border-radius:0 0 .25rem 0;
	}
	
}

@media screen and (max-width: 800px) {
.right-sidebar {
    background-color: #e8ecef;
	width: 800px;
    padding-top:25px;
	
}
}
@media screen and (min-width: 800px) {
	.right-sidebar {
	font-size:90%;
	}
}

.right-sidebar h3 {
    font-size: 1.2em;
	font-weight: 700;
    padding-left: 5px;
    color: #222;
    border-left: 4px solid #efb310;
}
.right-sidebar .textwidget {
    padding-left: 10px;
}
.right-sidebar aside{
	margin-bottom: 30px;
}
.forestry-h4 {
    text-transform: uppercase;
    color: #444;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px !important;
}
h3.lp {
    font-family: Oswald, arial, helvetica, sans-serif;
    font-size: 2em !important;
    margin-bottom: 4px;
    padding-bottom: 2px;
    margin-top: 0;
    color: #666 !important;
    font-weight: 300 !important;
}
	@media screen and (max-width: 800px) {
	#nav_menu-5,#nav_menu-6,#nav_menu-7,#nav_menu-8{
	visibility:hidden;
	height:0px;
}
		
}
	@media screen and (max-width: 800px) {
	.fourfooter, .footerfull{
	padding:0px;
}
		
}
	@media screen and (max-width: 800px) {
	#video{
	visibility:hidden;
	height:0px;
}
}

#search-box {
    float: right;
    margin-right: 15%;
   
}
.fourfooter ul li:first {text-transform: uppercase;border-bottom:1px solid #ccc;padding-bottom:10px;font-size: 1em; font-weight:bold;}
.navbar-brand {
    padding: 20px 0 20px 0%;
        
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0 10%;
}
.home-card {
    display: inline-block;
	 border:none;
	 width:12%;
	 padding:15px;
  }
.home-card a {color: #444!important}

@media screen and (max-width:800px){
	.home-card 
	{
		min-width:150px;
	}
}

.disclaimers{font-size:.9em}

.proxima-header{
	color: #444;
	font-family: "proxima-nova-extra-condensed",sans-serif;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 10px;
	text-transform: uppercase;
	width: 100%;
}
// HOME PAGE SEARCH //

.home-inventory-search-wrapper{
	padding: 40px 0;
	margin-top: 50px;
	background-color: #efb310;
	min-height:200px;
}
.home-inventory-search-wrapper h2{
	color: #444;
	font-family: "proxima-nova-extra-condensed",sans-serif;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 10px;
	text-transform: uppercase;
	width: 100%;
}
.home-inventory-search{
	width:100%;
}
.search-facet{
        margin-top: -2px;
}
.search-button{
        /* margin-top: -4px; */
        padding-top:20px;
}
.search-facet .facetwp-facet{margin-bottom: 0!important}
.search-facet h3{
	font-family:"Open Sans";
	font-size: 12px;
	color: #444;
	margin-bottom:2px;
}
.home-inventory-search .facetwp-type-dropdown{
	min-height:36px!important;
	border: 1px solid #ccc;
	border-radius: 6px;
	background: #fafafa url("data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==") no-repeat 96% 50%;
}
.home-inventory-search button{
        display: inline-block;
        background-color: #000;
        padding: 6px 20px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 6px;
        color: #fff;
        font-family: 'Open Sans';
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid #000;
    }

.home-inventory-search .facetwp-type-dropdown select{
    font-size:12px;
    padding: 10px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none!important; 
   -moz-appearance: none!important;
   appearance: none!important;
}

.home-inventory-search .facetwp-type-dropdown select::-ms-expand { 
    display: none; /* hide the default arrow in ie10 and ie11 */
}

.home-inventory-search .facetwp-type-dropdown select:focus {
    outline: none;
}

.home-newsletter p{
	margin: auto;
    font-weight: 100!important;
    font-size: 1.5rem;
    letter-spacing: .12rem;
    text-align: -webkit-center;
    text-transform: uppercase;
}

.stock-content{padding:25px 12px}
.stock-item{padding:8px;margin:0!important;border-left:none!important;border-right:none!important;border-top:1px solid #aeaeae;border-bottom:none!important;}
.stock-item-image{}
.stock-item-caption{padding:0 8px}
.stock-item-buttons{width:160px;margin-top:10px}
.stock-item-buttons img{padding-bottom:5px}
.seller-info p{font-size:1.2em;line-height:1.5em;}
.seller-info .btn{text-align:center;float:right;margin-right:60px;}
.si-number{font-weight:bold;font-size:1.28em;}
.mileage{font-size:.83em}

.stock-listing{margin-top:10px;}
.stock-list-wrap, .used-list-wrap{position:relative;}
.stock-list-wrap .ajax-spinner,.used-list-wrap .ajax-spinner{display:none;position:absolute;z-index:10001;width:100%;height:100%;background:url(../images/loading-bg.png);}
.stock-list-wrap.loading .ajax-spinner,.used-list-wrap.loading .ajax-spinner {display:block;opacity: .9;filter: alpha(opacity=90);background-color:#FFF;}
.stock-list{min-height:600px!important;}
.ajax-spinner .please-wait {text-align:center;padding-top:5%;}
.no-results{margin-left:20px;margin-right:20px;padding:12px;font-weight:700;font-size:1.1em;line-height:1.5em;}

#request-cta{line-height:4em;display: table-cell;vertical-align: middle;padding:20px;}
#request-cta img{margin-top:-8px;}
#request-cta p{padding-top:10px;}
.phone-cta{font:16px/1 Oswald, arial, helvetica, sans-serif;color:#666!important;}

.inventory-title{font:18px/1 Oswald, arial, helvetica, sans-serif!important;color:#666!important;font-weight:normal!important;margin:5px 0!important;padding-right:10px;}
.inventory-note{font:16px/1.5 Oswald, arial, helvetica, sans-serif;color:#666!important;}
.inventory-price{font:18px/1 Oswald, arial, helvetica, sans-serif;color:#666!important;}
.inventory-bullets{font-size:14px;margin:0!important;padding:0!important;list-style:none!important}
.inventory-bullets li{margin:0!important;padding:0!important}
.inventory-specs{
  padding-left: 0!important;
  list-style: none!important;
  line-height:1.8em;
}

.list-contact{margin:10px 0 0 2px;}
.lc-title{color:#333!important;font-weight:bold!important;}
.lc-quote{margin-left:30px!important;}

.financing-blurb{font-family: Arial,Helvetica Neue,Helvetica,sans-serif;font-size:11px!important;line-height:11px!important; font-weight:600; padding:6px 0 8px 0; border-top: 1px dotted #DEDEDE;}

.financing-blurb-pg{font-size:11px!important;line-height:11px!important; font-weight:normal; padding:6px 0 8px 0; border-top: 1px dotted #DEDEDE;}
.inventory-title-h2{font:20px/1 Oswald, arial, helvetica, sans-serif!important;color:#666!important;float:left;}
.inventory-title-h3{font:16px/1 Oswald, arial, helvetica, sans-serif!important;color:#666!important;margin-bottom:15px;border-left:5px solid #efb310;padding-left:6px;}

.facetwp-pager{text-align:center!important}
.facetwp-type-slider{margin-top:10px;}
.facetwp-slider-label{font-size:12px!important;}
.facetwp-slider-label:after{content: " ft"!important;}

.facetwp-selections ul {
	padding-left:0;
}
.facetwp-selections ul li{
    font-weight: 200;
	padding:5px;
	margin-bottom:2px;
	-webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.facetwp-selections .facetwp-selection-value{
    padding:5px;
    padding-right: 25px!important;
    background-color: #d9edf7;
    background-position: right 5px center!important;
}
.facetwp-type-number_range{padding:6px 0 6px 12px}
.facetwp-type-number_range label{font-size:12px!important}

.facetwp-sort{display:inline!important;}
.facetwp-sort-select{height:20px!important;font-size:11px!important;padding:1px 4px!important;width:150px!important}
.facetwp-per-page-select{height:20px!important;font-size:11px!important;padding:1px 4px!important;width:150px!important}
.facetwp-per-page{float:right;}

.filtered-list{padding:6px 0 6px 12px}
.filtered-list a{text-transform:uppercase;color:#FFFFFF;text-decoration:none;}
.price-label input{margin-right:5px!important;}

.btn-search{margin:0 0 0 5px!important;}
.facetwp-facet-inventory_search{margin-bottom:0!important}

.sortbox{}
.paging{text-align:center;margin:10px 0;border-top:1px dotted #eaeaea;padding-top:10px;}
.facetwp-type-radio, .facetwp-radio{display:inline;}
.sortlabel{font-size:10px;font-weight:bold;text-transform:uppercase;}

.facetwp-selection-label{display:none;}

.info-banner{
margin-top: -10px;
padding-top: 8px;
max-width: 188px;
height: 25px;
color: #fff;
text-align: center;
text-transform: uppercase;
font-size: .725rem;
font-weight: 600;
}
.coming{background-color: #343a40;}
.sold,.reduced{background-color: #dc3545;}
.pending{background-color: #ffc107;}
.processing{background-color: #17a2b8;}
.demo{background-color:#28a745}

.undercdl{
padding-top: 10px;
max-width: 188px;
height: 25px;
color: #fff;
text-align: center;
text-transform: uppercase;
font-size: .725rem;
font-weight: 600;}


.product_selection{margin:0 0 19px;background-color:#ebebeb;padding:4px 10px 4px 4px;}
.inventory_selection{margin:0 0 8px;background-color:#ebebeb;padding:4px 10px 4px 4px;}
.breadcrumb-link{color:#444;font-weight:700;background:url(images/arrow_right_gold.png) no-repeat scroll 0 1px transparent;padding-left:12px}
.product-social{padding-left:30px;}
.social-link{padding:0 10px 0 0!important;float:left;width:90px}
.social-link.fb{width:130px}
#print-page{float:right;}
#print-page #print-link{display:block;text-indent:-9999px;width:20px;height:20px;background:url(images/print-icon.png) no-repeat}
#print{float:none;}
#print #print-link{display:block; text-indent:-9999px;width:154px;height:44px; background:url('images/print-page.jpg') no-repeat;}
.print-title{display:none}

.product-title{font:32px/1 Oswald, arial, helvetica, sans-serif;color:#444!important;}
.product-bullets{font-size:14px;margin-top: 20px}
.inventory-data{padding-top:25px;}
.price{margin:10px 60px 0 20px;padding:10px 0;font:26px/1.2 Oswald, arial, helvetica, sans-serif;color:#444!important;border-top:1px dotted #ccc;border-bottom:1px dotted #ccc}
.calc{width:29px;height:33px;float:right;}
.calc-results{margin-top:0;}
.calc-results h4{font-weight:700;font-size:16px;}
.calc-results p{font-weight:700;font-size:16px;;padding-top:10px!important;}
.calc-results p.disclaimers{font-size:.9em;font-weight:normal;}

.product-slider {padding:25px}
.product-slider #unit-carousel {margin: 0; }
.product-slider .carousel-item img { width: 100%; height: auto; }
.product-slider #thumbcarousel { margin: 12px 0 0;}
.product-slider #thumbcarousel .carousel-item { text-align: center; }
.product-slider #thumbcarousel .carousel-item .thumb { border: 1px solid #cecece; width: 15%; margin: 0 1%; display: inline-block; vertical-align: middle; cursor: pointer; max-width: 98px; }
.product-slider #thumbcarousel .carousel-item .thumb:hover { border-color: #1089c0; }

#thumbcarousel .carousel-control-prev,#thumbcarousel .carousel-control-next{ color: #0284b8; text-align: center; text-shadow: none; font-size: 30px; width: 30px; height: 30px; line-height: 20px; top: 23%; }
#thumbcarousel .carousel-control-prev:hover,#thumbcarousel .carousel-control-next:hover,#thumbcarousel .carousel-control-next:focus,.carousel-control-prev:focus,#thumbcarousel .carousel-control-prev:active,#thumbcarousel .carousel-control-next:active { color: #333; }
#thumbcarousel .carousel-caption,#thumbcarousel .carousel-control-prev .fa,#thumbcarousel .carousel-control-next .fa { font: normal normal normal 30px/26px FontAwesome; }
#thumbcarousel .carousel-control-prev,#thumbcarousel .carousel-control-next { background-color: rgba(0, 0, 0, 0); background-image: none; }

.home-row{margin-left:0; margin-right:0px;}

/* Facet Accordion */
.filter-header{border-top: 5px solid $primary;}
.facetwp-selections{display: none};
.collapse {
  display: none;
  &.show {
    display: block;
  }
  &.in {
    display: block;
  }
}

[data-toggle="collapse"] .fa:after {
  content: "\f107";
}

[data-toggle="collapse"].collapsed .fa:after {
  content: "\f105";
}
#filter-accordion, #mobile-footer-nav{
    .btn-link{font-size:.875rem!important; color:#444;text-decoration: none;padding-left:.5rem;width:100%;text-align:left;white-space: normal!important}
    .card-header{padding:.25rem 0;background-color:rgba(255,255,255,1.00);border-top: 1px solid rgba(0,0,0,.125);border-bottom:none;}
    .card{border: none}
    .card-body{padding:.65rem;}
    .facetwp-facet{font-weight:200;margin-bottom: 20px;}
	.fa {float: right;line-height:20px}
}
#mobile-footer-nav{
ul{margin:0 6px; list-style: none;padding:0 6px}
ul li{padding:6px 0px;}
ul li a{color:#555}
ul li.hover a{color:$primary}
ul li.active a{color:$primary}
}

.filter-header{padding:20px 0}
.result-count {font-weight: 800;font-size: 1.25rem;line-height: 1.25rem;}
.result-text {font-weight: 600;font-size: .825rem;line-height: .825rem;}
.facetwp-facet-inventory_search .form-group{margin-bottom:0}

/* PRODUCT TABLES */
/* bootstrap uses <i> for icons */
.tablesorter-bootstrap .tablesorter-header i {
	position: absolute;
	right: 2px;
	top: 50%;
	margin-top: -7px; /* half the icon height; older IE doesn't like this */
	width: 14px;
	height: 14px;
	background-repeat: no-repeat;
	line-height: 14px;
	display: inline-block;
}
.tablesorter-bootstrap .bootstrap-icon-unsorted {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAYAAAD5YeaVAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWVJREFUeNqUUL9Lw2AUTGP8mqGlpBQkNeCSRcckEBcHq1jImMElToKuDvpHFMGhU0BQcHBwLji6CE1B4uB/INQsDi4d2jQ/fPeZxo764OV6915f7lLJ81xot9tCURXqdVEUr7IsO6ffH9Q5BlEUCaLwWxWqTcbYnaIoh0Dw4gAvcWlxq1qt9hqNxg6hUGAP+uIPUrGs0qXLer2+v/pTX6QpxLtkc2U2m53ACb8sSdIDXerSEms2m6+DweAICA4d89KGbduf9MpEVdXQ9/2LVqv1CASHjjn3iq/x1xKFfxQPqGnada1W86bT6SiO42OS3qk3KPStLMvbk8nkfjwen/LLuq6blFymMB0KdUPSGhAcOualjX6/f0bCiC7NaWGPQr0BwaFjzn0gYJqmLAiCA8/zni3LmhuGkQPBoWPOPwQeaPIqD4fDruu6L6Zp5kBw6IudchmdJAkLw3DXcZwnIPjy/FuAAQCiqqWWCAFKcwAAAABJRU5ErkJggg==);
}

/* since bootstrap (table-striped) uses nth-child(), we just use this to add a zebra stripe color */
.tablesorter-bootstrap tr.odd td {
	background-color: #f9f9f9;
}
.tablesorter-bootstrap tbody > .odd:hover > td,
.tablesorter-bootstrap tbody > .even:hover > td {
	background-color: #f5f5f5;
}
.tablesorter-bootstrap tr.even td {
	background-color: #fff;
}

/* processing icon */
.tablesorter-bootstrap .tablesorter-processing {
	background-image: url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=');
	position: absolute;
	z-index: 1000;
}

/* filter widget */
.tablesorter-bootstrap .tablesorter-filter-row .tablesorter-filter {
	width: 98%;
	height: auto;
	margin: 0 auto;
	padding: 4px 6px;
	background-color: #fff;
	color: #333;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: height 0.1s ease;
	-moz-transition: height 0.1s ease;
	-o-transition: height 0.1s ease;
	transition: height 0.1s ease;
}
.tablesorter-bootstrap .tablesorter-filter-row .tablesorter-filter.disabled {
	background: #eee;
	cursor: not-allowed;
}
.tablesorter-bootstrap .tablesorter-filter-row td {
	background: #eee;
	line-height: normal;
	text-align: center;
	padding: 4px 6px;
	vertical-align: middle;
	-webkit-transition: line-height 0.1s ease;
	-moz-transition: line-height 0.1s ease;
	-o-transition: line-height 0.1s ease;
	transition: line-height 0.1s ease;
}
/* hidden filter row */
.tablesorter-bootstrap .tablesorter-filter-row.hideme td {
	padding: 2px; /* change this to modify the thickness of the closed border row */
	margin: 0;
	line-height: 0;
}
.tablesorter-bootstrap .tablesorter-filter-row.hideme .tablesorter-filter {
	height: 1px;
	min-height: 0;
	border: 0;
	padding: 0;
	margin: 0;
	/* don't use visibility: hidden because it disables tabbing */
	opacity: 0;
	filter: alpha(opacity=0);
}

/* pager plugin */
.tablesorter-bootstrap .tablesorter-pager select {
  padding: 4px 6px;
}
.tablesorter-bootstrap .tablesorter-pager .pagedisplay {
	border: 0;
}

/* ajax error row */
.tablesorter .tablesorter-errorRow td {
	cursor: pointer;
	background-color: #e6bf99;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.unit-price{padding-top:10px;}
	.unit-search{padding:20px;}
	.tablesorter-bootstrap .tablesorter-processing,.tablesorter-bootstrap .bootstrap-icon-unsorted {background-image: none!important}
	.tablesorter-bootstrap .tablesorter-processing .fa,.tablesorter-bootstrap .bootstrap-icon-unsorted .fa {display: none!important}
}
.item-blurb{min-height:100px;}
.metric{color:#888;font-size: .825rem}
.share-page a{font-size:11px;color:#444;text-transform:uppercase;cursor: pointer}
.gf-link{width:305px;color:#fff;z-index:1;position:absolute!important;left:5px!important;top:167px!important;background-color:rgba(85,136,0,0.65);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#A5558800',endColorstr='#A5558800');-ms-filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#A5558800',endColorstr='#A55588003');padding:10px}
.gf-link:hover{background-color:rgba(85,136,0,0.55);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C558800',endColorstr='#8C558800');-ms-filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C558800',endColorstr='#8C558800');cursor:pointer}
.gf-link:hover a{text-decoration:underline}
.gf-link a{color:#fff;font-weight:700;font-size:12px}

/* Related Articles */
.article{padding:10px 0!important;border-bottom:1px dotted #ededed}
.article-header{font-size:26px!important;line-height:25px!important;font-weight:400!important}
.article h2{font-size:18px!important;font-weight:300!important;margin-bottom: 0!important}
.article h2 a{color:#2b5376!important}
.article h2 a:hover{color:#444!important;background-color:#efefef;border-bottom:1px solid #efb310;text-decoration:none}

#mlp-banners{
	.carousel-indicators {
	  li {
		background-color: rgba(239,179,16,.5);
	  }
	  .active {
		background-color:#efb310;
	  }
	}
}
.effer-product-list{
	li {
	  display: inline-block;
	  height: 50px;
	  width: 50px!important;
	  line-height: 1.3;
		margin-right:5px;
	}
	li a {
	  background: #dadada;
	  color: #ffffff;
	  text-align: center;
	  text-transform: uppercase;
	  text-decoration:none!important;
	  width: 100%;
	  height: 100%;
	  display: table;
	  -moz-border-radius: 50%;
	  -webkit-border-radius: 50%;
	  border-radius: 50%;
	  opacity: 1;
	  -moz-transition-property: background-color;
	  -o-transition-property: background-color;
	  -webkit-transition-property: background-color;
	  transition-property: background-color;
	  -moz-transition-duration: 0.3s;
	  -o-transition-duration: 0.3s;
	  -webkit-transition-duration: 0.3s;
	  transition-duration: 0.3s;
	  -moz-transition-timing-function: ease-in;
	  -o-transition-timing-function: ease-in;
	  -webkit-transition-timing-function: ease-in;
	  transition-timing-function: ease-in;
	}
	li a span {
	  display: table-cell;
	  vertical-align: middle;
	  font-family: "Roboto Condensed", sans-serif;
	  font-weight: 300;
	  color: #3b3b3b;
	  width: 100%;
	  font-size: 1rem;
	}
	li a:hover {
  	background-color: #9d9d9d;
	}
}
/** Gravity Forms */
.gform_wrapper form {
  margin-bottom: 0;
}

.gform_wrapper ul {
  @extend .list-unstyled;
}

.gform_wrapper li {
  @extend .form-group;
}

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $danger;
}

.gform_wrapper input[type="email"],
.gform_wrapper input[type="date"],
.gform_wrapper input[type="datetime"],
.gform_wrapper input[type="datetime-local"],
.gform_wrapper input[type="month"],
.gform_wrapper input[type="number"],
.gform_wrapper input[type="password"],
.gform_wrapper input[type="search"],
.gform_wrapper input[type="tel"],
.gform_wrapper input[type="text"],
.gform_wrapper input[type="time"],
.gform_wrapper input[type="week"],
.gform_wrapper input[type="url"],
.gform_wrapper select,
.gform_wrapper textarea {
  @extend .form-control;
}

.gform_wrapper .button {
  @extend .btn;
  @extend .btn-primary;
}

.gform_wrapper .gfield_error {
  .gfield_label {
    @extend .alert-danger;
  }
  input,
  select,
  textarea {
    @extend .alert-danger;
  }
}
.validation_error {
  @extend .alert;
  @extend .alert-danger;
}
.validation_message {
  display: none;
}
.gform_wrapper .field_name_first,.gform_wrapper .field_name_last,.gform_wrapper .field_name_middle {
 width:49.5%;
 display:-moz-inline-stack;
 display:inline-block;
 vertical-align:top
}
.gform_wrapper .ginput_complex.ginput_container {
 overflow:visible;
 width:100%
}
.gform_wrapper .ginput_complex .ginput_left input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="image"]):not([type="file"]), .gform_wrapper .ginput_complex .ginput_right input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="image"]):not([type="file"]), .gform_wrapper .ginput_complex .ginput_right select, .gform_wrapper .ginput_complex input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="image"]):not([type="file"]), .gform_wrapper .ginput_complex select {
    width: 100%;
}
div.ginput_container_name span {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    padding-right: 16px;
    margin-right: -4px;
}
div.ginput_complex.ginput_container.gf_name_has_2 span {
    width: 50%;
}
// GRAVITY FORM READY CLASSES //
@media only screen and (min-width: 641px) {

    .gform_wrapper .top_label li.gfield.gf_inline {
        vertical-align: top;
        width: auto !important;
        margin: 0;
        padding-right: 16px;
        float: none !important;
        display: -moz-inline-stack;
        display: inline-block;
    }

    .gform_wrapper .top_label li.gfield.gf_inline input[type=text].large,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=url].large,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=email].large,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=tel].large,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=number].large,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=password].large,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=text].medium,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=url].medium,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=email].medium,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=tel].medium,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=number].medium,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=password].medium,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=text].small,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=url].small,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=email].small,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=tel].small,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=number].small,
    .gform_wrapper .top_label li.gfield.gf_inline input[type=password].small {
        width: 100%;
    }

    .gform_wrapper .top_label li.gfield.gf_inline input[type=text].datepicker.medium {
        width: 96px !important;
    }

    .gform_wrapper .top_label li.gfield.gf_inline select {
        width: 100%;
    }

    .gform_wrapper .top_label li.gfield.gf_inline textarea {
        width: 100%;
    }

    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_hour input[type=text],
    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_minute input[type=text] {
        width: 70% !important;
    }

    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_hour,
    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_minute,
    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_month,
    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_day,
    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_year {
        width: 50px;
    }

    .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_ampm {
        width: auto;
    }

    .gform_wrapper li.gf_inline div.ginput_container {
        white-space: nowrap !important;
    }

    .gform_wrapper li.gf_inline div.ginput_container.ginput_container_date label {
        display: block !important;
    }


    .gform_wrapper li.gfield.gfield_error.gf_inline {
        margin-right: 16px;
        padding-right: 0;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half,
    .gform_wrapper .top_label li.gfield.gf_right_half {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding-right: 16px;
        float: none;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker),
    .gform_wrapper .top_label li.gfield.gf_right_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker) {
        width: 100% !important;
        margin: 8px 0 0 0;
        padding-left: 0;
        padding-right: 0;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half:before {
        content: "";
        display: block;
        clear: both;
    }

    .gform_wrapper .top_label li.gfield.gf_right_half:after {
        content:"";
        display: table;
        clear:both;
    }

    .gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_left_half,
    .gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_right_half {
        max-width: calc(50% - 16px);
        padding-right: 0;
    }

    .gform_wrapper .top_label li.gfield.gfield_error.gf_left_half {
        margin-right: 16px;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) input.medium,
    .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) input.large,
    .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) select.medium,
    .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) select.large,
    .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) input.medium,
    .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) input.large,
    .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) select.medium,
    .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) select.large {
        width: 100%;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half textarea,
    .gform_wrapper .top_label li.gfield.gf_right_half textarea {
        width: 98%;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half input.small,
    .gform_wrapper .top_label li.gfield.gf_right_half input.small {
        width: 35%;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half+li.gsection,
    .gform_wrapper .top_label li.gfield.gf_right_half+li.gsection {
        padding: 16px 0 8px 0;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half+li.gfield.gsection.gf_left_half,
    .gform_wrapper .top_label li.gfield.gf_right_half+li.gfield.gsection.gf_left_half,
    .gform_wrapper .top_label li.gfield.gf_left_half+li.gfield.gsection.gf_right_half,
    .gform_wrapper .top_label li.gfield.gf_right_half+li.gfield.gsection.gf_right_half,
    .gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_left_half,
    .gform_wrapper .top_label li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_left_half,
    .gform_wrapper .top_label li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_right_half {
        padding: 16px 0 8px 0;
    }

    .gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half {
        padding: 0 0 8px 0;
    }

    .gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half {
        padding: 16px 0 8px 0;
    }

    .gform_wrapper .top_label li.gfield.gf_left_half+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_right_half+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_left_half+.gform_footer,
    .gform_wrapper .top_label li.gfield.gf_right_half+.gform_footer {
        clear: both;
    }

    /* 3 columns */

    .gform_wrapper .top_label li.gfield.gf_left_third,
    .gform_wrapper .top_label li.gfield.gf_middle_third,
    .gform_wrapper .top_label li.gfield.gf_right_third {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        float: none;
    }

    .gform_wrapper .top_label li.gfield.gf_left_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
    .gform_wrapper .top_label li.gfield.gf_middle_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
        width: 33.3%;
    }

    .gform_wrapper .top_label li.gfield.gf_right_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
        width: 33.4%;
    }

    .gform_wrapper .top_label li.gfield.gf_left_third,
    .gform_wrapper .top_label li.gfield.gf_middle_third,
    .gform_wrapper .top_label li.gfield.gf_right_third {
        margin-bottom: 8px;
    }

    .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input.medium,
    .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input.large,
    .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select.medium,
    .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select.large,
    .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input.medium,
    .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input.large,
    .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select.medium,
    .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select.large,
    .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input.medium,
    .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input.large,
    .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select.medium,
    .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select.large {
        width: 100%;
    }

    .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']):not(.ginput_quantity),
    .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']):not(.ginput_quantity),
    .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']):not(.ginput_quantity),
    .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select,
    .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select,
    .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select {
        width:100% !important;
    }

    .gform_wrapper .top_label li.gfield.gfield_error.gf_left_third,
    .gform_wrapper .top_label li.gfield.gfield_error.gf_middle_third {
        width: calc(33.3% - 24px) !important;
        margin-right: 16px;
    }

    .gform_wrapper .top_label li.gfield.gfield_error.gf_right_third {
        width: 33.4%;
    }

    .gform_wrapper .top_label li.gfield.gf_right_third:after {
        content:"";
        display: table;
        clear: both;
    }

    .gform_wrapper .top_label li.gfield.gf_left_third+li.gsection,
    .gform_wrapper .top_label li.gfield.gf_right_third+li.gsection {
        padding: 16px 0 8px 0;
    }

    .gform_wrapper .top_label li.gfield.gf_left_third+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_middle_third+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_right_third+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_left_third+.gform_footer,
    .gform_wrapper .top_label li.gfield.gf_middle_third+.gform_footer,
    .gform_wrapper .top_label li.gfield.gf_right_third+.gform_footer {
        clear: both;
    }

    /* 4 columns - quarters */

    .gform_wrapper .top_label li.gfield.gf_first_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
    .gform_wrapper .top_label li.gfield.gf_second_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
    .gform_wrapper .top_label li.gfield.gf_third_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
        width: 25%;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        float: none;
    }

    .gform_wrapper .top_label li.gfield.gf_first_quarter,
    .gform_wrapper .top_label li.gfield.gf_second_quarter,
    .gform_wrapper .top_label li.gfield.gf_third_quarter,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter {
        margin-bottom: 8px;
    }

    .gform_wrapper .top_label li.gfield.gf_first_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
    .gform_wrapper .top_label li.gfield.gf_second_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
    .gform_wrapper .top_label li.gfield.gf_third_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
    .gform_wrapper .top_label li.gfield.gf_first_quarter div:not(.ginput_container_date) select,
    .gform_wrapper .top_label li.gfield.gf_second_quarter div:not(.ginput_container_date) select,
    .gform_wrapper .top_label li.gfield.gf_third_quarter div:not(.ginput_container_date) select,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter div:not(.ginput_container_date) select {
        width: 100% !important;
    }

    .gform_wrapper .top_label li.gfield.gfield_error.gf_first_quarter,
    .gform_wrapper .top_label li.gfield.gfield_error.gf_second_quarter,
    .gform_wrapper .top_label li.gfield.gfield_error.gf_third_quarter,
    .gform_wrapper .top_label li.gfield.gfield_error.gf_fourth_quarter {
        width: 24.5%;
    }

    .gform_wrapper .top_label li.gfield.gf_fourth_quarter:after {
        content: "";
        display: table;
        clear: both;
    }

    .gform_wrapper .top_label li.gfield.gf_first_quarter input.medium,
    .gform_wrapper .top_label li.gfield.gf_first_quarter input.large,
    .gform_wrapper .top_label li.gfield.gf_first_quarter select.medium,
    .gform_wrapper .top_label li.gfield.gf_first_quarter select.large,
    .gform_wrapper .top_label li.gfield.gf_second_quarter input.medium,
    .gform_wrapper .top_label li.gfield.gf_second_quarter input.large,
    .gform_wrapper .top_label li.gfield.gf_second_quarter select.medium,
    .gform_wrapper .top_label li.gfield.gf_second_quarter select.large,
    .gform_wrapper .top_label li.gfield.gf_third_quarter input.medium,
    .gform_wrapper .top_label li.gfield.gf_third_quarter input.large,
    .gform_wrapper .top_label li.gfield.gf_third_quarter select.medium,
    .gform_wrapper .top_label li.gfield.gf_third_quarter select.large,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter input.medium,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter input.large,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter select.medium,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter select.large {
        width: 97.5%;
    }

    .gform_wrapper .top_label li.gfield.gf_first_quarter+li.gsection,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter+li.gsection {
        padding: 16px 0 8px 0;
    }

    .gform_wrapper .top_label li.gfield.gf_first_quarter+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_second_quarter+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_third_quarter+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter+li.gfield,
    .gform_wrapper .top_label li.gfield.gf_first_quarter+.gform_footer,
    .gform_wrapper .top_label li.gfield.gf_second_quarter+.gform_footer,
    .gform_wrapper .top_label li.gfield.gf_third_quarter+.gform_footer,
    .gform_wrapper .top_label li.gfield.gf_fourth_quarter+.gform_footer {
        clear: both;
    }

    /* horizontal list columns - displayed inline */

    .gform_wrapper .top_label li ul.gfield_checkbox,
    .gform_wrapper .top_label li ul.gfield_radio {
        width: calc(100% + 16px);
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li ul.gfield_checkbox,
    .gform_wrapper ul.gform_fields:not(.top_label) li ul.gfield_radio {
        width: calc(70% + 16px);
    }

    .gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_2col ul.gfield_radio li {
        width: 50%;
        display: -moz-inline-stack;
        display: inline-block;
        margin: 0;
        padding-right: 16px;
        min-height: 28px;
        vertical-align: top;
        font-size: 90%;
    }

    .gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_3col ul.gfield_radio li {
        width: 33.3%;
        display: -moz-inline-stack;
        display: inline-block;
        margin: 0 0 2px 0;
        padding-right: 16px;
        min-height: 28px;
        vertical-align: top;
        font-size: 90%;
    }

    .gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox li label,
    .gform_wrapper li.gfield.gf_list_2col ul.gfield_radio li label,
    .gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox li label,
    .gform_wrapper li.gfield.gf_list_3col ul.gfield_radio li label,
    .gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li label,
    .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li label {
        max-width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

    .gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li {
        width: 25%;
        display: -moz-inline-stack;
        display: inline-block;
        margin: 0;
        padding-right: 16px;
        min-height: 28px;
        vertical-align: top;
        font-size: 90%;
    }

    .gform_wrapper li.gfield.gf_list_5col ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_5col ul.gfield_radio li {
        width: 20%;
        display: -moz-inline-stack;
        display: inline-block;
        margin: 0;
        padding-right: 16px;
        min-height: 28px;
        vertical-align: top;
        font-size: 90%;
    }

    .gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox,
    .gform_wrapper li.gfield.gf_list_2col ul.gfield_radio,
    .gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox,
    .gform_wrapper li.gfield.gf_list_3col ul.gfield_radio,
    .gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox,
    .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio,
    .gform_wrapper li.gfield.gf_list_5col ul.gfield_checkbox,
    .gform_wrapper li.gfield.gf_list_5col ul.gfield_radio {
        overflow: hidden;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_2col label.gfield_label,
    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_3col label.gfield_label,
    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_4col label.gfield_label,
    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_5col label.gfield_label,
    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_inline label.gfield_label {
        margin: 0 0 10px 0;
    }

    /* vertical list columns - using CSS columns */

    .gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox,
    .gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox,
    .gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox,
    .gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox {
        -webkit-column-gap: 16px;
        -moz-column-gap: 16px;
        column-gap: 16px;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label,
    .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label,
    .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label,
    .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label {
        margin: 0 0 10px 0;
    }

    .gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox,
    .gform_wrapper li.gf_list_2col_vertical .ginput_container_radio ul.gfield_radio {
        width: calc(100% - 16px);
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    .gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox,
    .gform_wrapper li.gf_list_3col_vertical .ginput_container_radio ul.gfield_radio {
        width: calc(100% - 8px);
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }

    .gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox,
    .gform_wrapper li.gf_list_4col_vertical .ginput_container_radio ul.gfield_radio {
        width: calc(100% - 8px);
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
    }

    .gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox,
    .gform_wrapper li.gf_list_5col_vertical .ginput_container_radio ul.gfield_radio {
        width: calc(100% - 8px);
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5;
    }

    .gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
    .gform_wrapper li.gf_list_2col_vertical .ginput_container_radio ul.gfield_radio li label,
    .gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
    .gform_wrapper li.gf_list_3col_vertical .ginput_container_radio ul.gfield_radio li label,
    .gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
    .gform_wrapper li.gf_list_4col_vertical .ginput_container_radio ul.gfield_radio li label,
    .gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
    .gform_wrapper li.gf_list_5col_vertical .ginput_container_radio ul.gfield_radio li label {
        max-width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .gform_wrapper li.gfield.gf_list_height_25 ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_height_25 ul.gfield_radio li {
        height: 25px;
    }

    .gform_wrapper li.gfield.gf_list_height_50 ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_height_50 ul.gfield_radio li {
        height: 50px;
    }

    .gform_wrapper li.gfield.gf_list_height_75 ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_height_75 ul.gfield_radio li {
        height: 75px;
    }

    .gform_wrapper li.gfield.gf_list_height_100 ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_height_100 ul.gfield_radio li {
        height: 100px;
    }

    .gform_wrapper li.gfield.gf_list_height_125 ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_height_125 ul.gfield_radio li {
        height: 125px;
    }

    .gform_wrapper li.gfield.gf_list_height_150 ul.gfield_checkbox li,
    .gform_wrapper li.gfield.gf_list_height_150 ul.gfield_radio li {
        height: 150px;
    }

    .gform_wrapper li.gf_list_inline ul.gfield_checkbox li,
    .gform_wrapper li.gf_list_inline ul.gfield_radio li {
        width: auto !important;
        float: none !important;
        margin: 0 8px 10px 0;
        display: -moz-inline-stack;
        display: inline-block;
    }

    html[dir="rtl"] .gform_wrapper li.gf_list_inline ul.gfield_checkbox li,
    html[dir="rtl"] .gform_wrapper li.gf_list_inline ul.gfield_radio li {
        padding-left: 16px !important;
        padding-right: 0 !important;
    }

    .gform_wrapper li.gf_hide_ampm div.gfield_time_ampm {
        display: none !important;
    }

    .gform_wrapper li.gsection.gf_scroll_text {
        height: 240px;
        width: calc(100% - 16px);
        padding: 16px;
        background-color: #fff;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid #CCC;
        border-bottom: 1px solid #CCC !important;
    }

    .gform_wrapper .top_label li.gsection.gf_scroll_text h2.gsection_title {
        margin: 10px 10px 0 8px !important
    }

    .gform_wrapper.gf_browser_gecko .top_label li.gsection.gf_scroll_text h2.gsection_title,
    .gform_wrapper.gf_browser_chrome .top_label li.gsection.gf_scroll_text h2.gsection_title {
        margin: 0 10px 0 8px !important;
    }

    .gform_wrapper .top_label li.gsection.gf_scroll_text .gsection_description {
        margin: 10px;
        font-size: 12px;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul li,
    .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul li {
        list-style-type: disc !important;
        margin: 0 0 8px;
        overflow: visible;
        padding-left: 0;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul,
    .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul {
        list-style-type: disc !important;
        margin: 16px 0 16px 18px;
        padding-left: 0;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li,
    .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li {
        list-style-type: decimal!important;
        overflow: visible;
        margin: 0 0 8px;
        padding-left: 0;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol,
    .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol {
        list-style-type: decimal!important;
        margin: 16px 0 18px 32px;
        padding-left: 0;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul,
    .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul li {
        list-style-type: disc !important;
        padding-left: 0;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul {
        margin: 16px 0 16px 18px;
    }

    .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul li {
        margin: 0 0 8px;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl {
        margin: 0 0 18px 0;
        padding-left: 0;
    }
    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl dt {
        font-weight: 700;
    }

    .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl dd {
        margin: 0 0 16px 18px;
    }

    .gform_wrapper li.gfield.gfield_html.gf_alert_green,
    .gform_wrapper li.gfield.gfield_html.gf_alert_red,
    .gform_wrapper li.gfield.gfield_html.gf_alert_yellow,
    .gform_wrapper li.gfield.gfield_html.gf_alert_gray,
    .gform_wrapper li.gfield.gfield_html.gf_alert_blue {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        margin: 20px 0 !important;
        padding: 20px !important;
    }

    .gform_wrapper li.gfield.gfield_html.gf_alert_green {
        border: 1px solid #97B48A;
        background-color: #CBECA0;
        text-shadow: #DFB 1px 1px;
        color: #030;
    }

    .gform_wrapper li.gfield.gfield_html.gf_alert_red {
        border: 1px solid #CFADB3;
        background-color: #FAF2F5;
        text-shadow: #FFF 1px 1px;
        color: #832525;
    }

    .gform_wrapper li.gfield.gfield_html.gf_alert_yellow {
        border: 1px solid #E6DB55;
        background-color: #FFFBCC;
        text-shadow: #FCFAEA 1px 1px;
        color: #222;
    }

    .gform_wrapper li.gfield.gfield_html.gf_alert_gray {
        border: 1px solid #CCC;
        background-color: #EEE;
        text-shadow: #FFF 1px 1px;
        color: #424242;
    }

    .gform_wrapper li.gfield.gfield_html.gf_alert_blue {
        border: 1px solid #a7c2e7;
        background-color: #D1E4F3;
        text-shadow: #E0F1FF 1px 1px;
        color: #314475;
    }

    div.gform_confirmation_wrapper.gf_confirmation_simple_yellow {
        margin: 30px 0;
        max-width: 99%;
        border-top: 1px solid #E6DB55;
        border-bottom: 1px solid #E6DB55;
        padding: 32px;
        background-color: #FFFBCC;
        color: #424242;
        font-size: 25px;
    }

    div.gform_confirmation_wrapper.gf_confirmation_simple_gray {
        margin: 30px 0;
        max-width: 99%;
        border-top: 1px solid #CCC;
        border-bottom: 1px solid #CCC;
        padding: 32px;
        background-color: #EAEAEA;
        color: #424242;
        font-size: 25px;
    }

    div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient {
        position: relative;
        background-color: #FFFBD2;
        margin: 30px 0;
        border: 1px solid #E6DB55;
        -webkit-box-shadow: 0px 0px 5px rgba(221, 215, 131, 0.75);
        -moz-box-shadow: 0px 0px 5px rgba(221, 215, 131, 0.75);
        box-shadow: 0px 0px 5px rgba(221, 215, 131, 0.75);
    }

    div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient div.gforms_confirmation_message {
        margin: 0;
        padding: 40px;
        max-width: 99%;
        font-size: 28px;
        border-top: 2px solid #FFF;
        border-bottom: 1px solid #E6DB55;
        background-color: #FFFBCC;
        color: #424242;
        background: #fffce5;
        background: -moz-linear-gradient(top,  #fffce5 0%, #fff9bf 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fffce5), color-stop(100%,#fff9bf));
        background: -webkit-linear-gradient(top,  #fffce5 0%,#fff9bf 100%);
        background: -o-linear-gradient(top,  #fffce5 0%,#fff9bf 100%);
        background: -ms-linear-gradient(top,  #fffce5 0%,#fff9bf 100%);
        background: linear-gradient(to bottom,  #fffce5 0%,#fff9bf 100%);
    }

    div.gform_confirmation_wrapper.gf_confirmation_green_gradient {
        position: relative;
        background-color: #f1fcdf;
        margin: 30px 0;
        border: 1px solid #a7c37c;
        -webkit-box-shadow: 0px 0px 5px rgba(86, 122, 86, 0.4);
        -moz-box-shadow: 0px 0px 5px rgba(86, 122, 86, 0.4);
        box-shadow: 0px 0px 5px rgba(86, 122, 86, 0.4);
    }

    div.gform_confirmation_wrapper.gf_confirmation_green_gradient div.gforms_confirmation_message {
        margin: 0;
        padding: 40px;
        max-width: 99%;
        font-size: 28px;
        border-top: 2px solid #effade;
        border-bottom: 1px solid #a7c37c;
        background-color: #FFFBCC;
        text-shadow: #DFB 1px 1px;
        color: #030;
        background: rgb(219,242,183);
        background: -moz-linear-gradient(top,  rgba(219,242,183,1) 0%, rgba(180,208,136,1) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(219,242,183,1)), color-stop(100%,rgba(180,208,136,1)));
        background: -webkit-linear-gradient(top,  rgba(219,242,183,1) 0%,rgba(180,208,136,1) 100%);
        background: -o-linear-gradient(top,  rgba(219,242,183,1) 0%,rgba(180,208,136,1) 100%);
        background: -ms-linear-gradient(top,  rgba(219,242,183,1) 0%,rgba(180,208,136,1) 100%);
        background: linear-gradient(to bottom,  rgba(219,242,183,1) 0%,rgba(180,208,136,1) 100%);
    }

    div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:before,
    div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:after,
    div.gform_confirmation_wrapper.gf_confirmation_green_gradient:before,
    div.gform_confirmation_wrapper.gf_confirmation_green_gradient:after {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width: 40%;
        background: rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 15px 10px rgba(0,0,0, 0.2);
        -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
        transform: rotate(-3deg);
    }

    div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:after,
    div.gform_confirmation_wrapper.gf_confirmation_green_gradient:after {
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
        transform: rotate(3deg);
        right: 10px;
        left: auto;
    }

    /* simple horizontal form ready class - very simple implementation for up to 5 fields and a button */

    .gform_wrapper.gf_simple_horizontal_wrapper.centered_wrapper {
        width: auto;
        margin: 0 auto;
        display: table;
    }

    .gform_wrapper form.gf_simple_horizontal {
        width: calc(100% - 16px);
        margin: 0 auto;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body,
    .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
        display: table-cell;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        position: relative;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body {
        width: auto;
        max-width: 75%;
    }

    .gform_wrapper form.gf_simple_horizontal div.ginput_container,
    .gform_wrapper form.gf_simple_horizontal ul li.gfield {
        margin-top: 0 !important;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
        width: auto;
        max-width: 25%;
        padding: 0;
        margin: 0;
    }

    body:not(rtl) .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
        text-align: left
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label {
        display: table;
        width: 100%;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield {
        display: table-cell;
        padding-right: 1em;
        height: auto;
    }

    .gform_wrapper form.gf_simple_horizontal label.gfield_label,
    .gform_wrapper form.gf_simple_horizontal .ginput_complex label,
    .gform_wrapper form.gf_simple_horizontal .gfield_description:not(.validation_message) {
        display: block;
        height: 1px;
        width: 1px;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: -9000px;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_below li.gfield {
        vertical-align: middle;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_above li.gfield {
        vertical-align: middle;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.small,
    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.medium,
    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.large {
        width: 100%
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.small,
    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.medium,
    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.large {
        width: 100%
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type="submit"],
    .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type="button"],
    .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type="image"] {
        height: auto;
    }

    .gform_wrapper form.gf_simple_horizontal .top_label .gfield_error {
        margin-bottom: 0;
    }

    .gform_wrapper form.gf_simple_horizontal ul li.gfield .ginput_container_checkbox ul li,
    .gform_wrapper form.gf_simple_horizontal ul li.gfield .ginput_container_radio ul li {
        display: inline-block;
        margin-right: .5em;
    }

    html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_2 span {
        width: 48.5%;
    }

    html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_3 span {
        width: 33%;
    }

    html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_4 span {
        width: 24.5%;
    }

    html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_5 span {
        width: 19.5%;
    }


/* end media query */

}

/* add equal padding to form for better centering */

body .gform_wrapper.gf_form_center_wrapper {padding: 16px 0 16px 16px;}


/* Events Plugin Custom Styles */
#evcal_list .eventon_list_event .evcal_desc span.evcal_event_title, .evo_lightboxes .evo_pop_body .evcal_desc span.evcal_desc2 {
    color: #efb310!important;
}

/* FAQ Styles */
#faqaccordion-canada,#faqaccordion-parts,#faqaccordion-safety,#faqaccordion-storms,#faqaccordion-supply,#faqaccordion-nueco{
    .btn-link{font-size:.875rem!important; color:#444;text-decoration: none;padding-left:.5rem;width:100%;text-align:left;white-space: normal!important}
    .card-header{padding:.25rem 0;background-color:rgba(0,0,0,.03);border-top: 1px solid rgba(0,0,0,.125);border-bottom:none;}
    .card{border: none}
    .card-body{padding:.65rem;}
	.fa {float: right;line-height:20px}
}

/* Worldwide Contact */
.europe{background:url(../images/worldwide/block_orange.gif) top left no-repeat}
.north-amer{background:url(../images/worldwide/block_green.gif) top left no-repeat}
.latin-amer{background:url(../images/worldwide/block_yellow.gif) top left no-repeat}
.asia-pac{background:url(../images/worldwide/block_blue.gif) top left no-repeat}
.middle-east{background:url(../images/worldwide/block_purple.gif) top left no-repeat}
.country-group-name{font-size:123.1%;color:#333;font-weight:700;padding:10px 0 0 21px}
.country{font-size:116%;font-weight:700}
.country-name{font-size:93%;font-weight:700}
.country-name a{color:#333}
.interactive-navigation{overflow:hidden;height:1%;}
.region-header{position:relative;border-top:1px solid #C1C1C1;clear:both}
.region-header h2{background-position:left 5px!important;line-height:100%!important;margin:0!important;padding:12px 0 11px 21px!important;font-size:123.1%!important;color:#333!important;cursor:pointer!important}
div.active-region{background-color:#efefef}
.region-header a{position:absolute!important;top:10px!important;right:15px!important;font-size:85%!important;color:#333!important;text-decoration:none!important;padding-right:22px!important}
#north-america .show-contacts{background:url(../images/worldwide/plus_green.gif) center right no-repeat}
#north-america .hide-contacts{background:url(../images/worldwide/minus_green.gif) center right no-repeat}
#latin-america .show-contacts{background:url(../images/worldwide/plus_yellow.gif) center right no-repeat}
#latin-america .hide-contacts{background:url(../images/worldwide/minus_yellow.gif) center right no-repeat}
#europe .show-contacts{background:url(../images/worldwide/plus_orange.gif) center right no-repeat}
#europe .hide-contacts{background:url(../images/worldwide/minus_orange.gif) center right no-repeat}
#middle-east-africa .show-contacts{background:url(../images/worldwide/plus_purple.gif) center right no-repeat}
#middle-east-africa .hide-contacts{background:url(../images/worldwide/minus_purple.gif) center right no-repeat}
#asia-pacific .show-contacts{background:url(../images/worldwide/plus_blue.gif) center right no-repeat}
#asia-pacific .hide-contacts{background:url(../images/worldwide/minus_blue.gif) center right no-repeat}
.country-links{padding:0 0 0 21px;display:none}
.country-links h3{display:none}
.country-links h4{font-size:1.1em!important;padding:6px 0;font-weight:700}
.country-links p{font-size:100%;margin:0 0 4px}
.country-selector{color:#333;text-decoration:none}
.country-display{background-color:#efefef;border:1px solid #dedede;}

/* Search Results */
.result-above-wrapper-area {
	border-bottom: 1px solid #E9E9E9;
	padding: 5px 0 5px 0;
	margin-bottom: 20px;
}
.result-title a {
	color: #15c !important;
	text-decoration: underline !important;
}
.result-title {
	font-size: 14px !important;
}
.result-title, .result-visibleUrl {
	padding-left: 2px !important;
}
.webResult div.result-visibleUrl-long {
	display: block;
}
.webResult div.result-visibleUrl{
	color: #009933!important;
}
.webResult div.result-visibleUrl-long{
	display: block;
}
.webResult div.result-visibleUrl-long{
	overflow: hidden;
}
.result a.result-visibleUrl, .result .result-visibleUrl {
	color: #093!important;
	text-decoration: none;
	padding-bottom: 2px;
}
.result-bidi-start-align {
	text-align: left;
}
.result-visibleUrl {
	font-size: 11px !important;
}
.result-title, .result-visibleUrl {
	padding-left: 2px !important;
}
.result {
	border-bottom: 1px dotted #ededed !important;
	padding-bottom: 20px !important;
	margin-bottom: 20px !important;
}

.result-snippet {
	margin-top: 1px;
	padding-left: 4px !important;
}
.result-snippet .more {
	display:none;
}
.result-info {
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 10px;
}
.result-info {
	text-align: left;
	color: #676767;
	font-size: 13px;
}
.no-results-result .result-snippet{
	padding: 5px;
	margin: 5px;
	border: 1px solid rgb(255,204,51);
	background-color: rgb(255,244,194);
}

.be-ix-link-block {
	padding: 0 0 1em!important;
}
.be-ix-link-block .be-related-link-container .be-related-link .headline {
	font-size: 14px!important;
}
h4.headline{font-size: .875rem!important}
.be-ix-link-block .be-label {
	font-size: 16px!important;
	margin: 10px auto!important;
}
.be-ix-link-block .be-related-link-container .be-related-link .desc {
	font-size: 12px!important;
}
.be-ix-link-block .be-related-link-container .be-related-link {
	border: 1px solid #ccc!important;
}
.be-ix-link-block .be-related-link-container .be-related-link:nth-child(3n+3), .be-ix-link-block .be-related-link-container .be-related-link:last-child {
	border: 1px solid #ccc!important;
}
.label-important,
.badge-important {
color: #fff;
background-color: #b94a48;
}
.new-listing-badge{line-height:20px;}
.published{color:#999;font-style:italic}
